export const Route = {
    Home : `${process.env.PUBLIC_URL || `/`}`,
    Dashboard : `/dashboard`,
    Chat : `/chat`,
    RightSidebar : `/chatzy/blog/right-sidebar`,
    LeftSidebar : `/chatzy/blog/left-sidebar`,
    SidebarDetails : `/chatzy/blog/sidebar-details`,
    NoSidebar : `/chatzy/blog/no-sidebar`,
    Login : `/auth/login`,
    Landing : `/chatzy`,
    Messenger : `/chatzy/messenger`,
    SignInClassic : `/auth/sign-in-classic`,
    SignUp : `/auth/sign-up`,
    SignUpClassic : `/auth/sign-up-classic`,
    ForgetPassword : `/auth/forget-password`,
    Logout: `/auth/logout`,
    About : `/chatzy/bonus/about`,
    Faq : `/chatzy/bonus/faq`,
    Elements : `/chatzy/bonus/elements`,
}