export const Href: string = "#JavaScript:void(0)";

export const Documentation: string = "Documentation";
export const ExclusiveFeaturesTitle: string = "Exclusive Features";
export const FeaturesText: string = "We Pervide Best Feature For App Design And Coding";
export const ViewMore: string = "VIEW MORE";
export const AboutApplicationTitle: string = "Easy to use our application";
export const AboutApplicationText: string = "All-in-one responsive app for you";
export const AboutApplicationDescription: string = "it is about us being able to offer help with the branding campaign, product presentation, and advertisement running across social media.";
export const TeamWorkTitle: string = "See Our Team work";
export const TeamWorkText: string = "Efficient teamwork for every enterprise";
export const ChatzyForEnterprise: string = "Chatzy for enterprise";
export const TeamExpertTitle: string = "Our Team";
export const TeamExpertText: string = "Our experts";
export const SecureAppTitle: string = "Secure Your Messages";
export const SecureAppText: string = "The world's top secure App";
export const SecureAppDescription: string = "Easy to use our chat app, attractive and clean design, with many features: Dark & light, recent and many more...";
export const LearnMore: string = "learn more";
export const PricePlanTitle: string = "Choose your pricing plan";
export const PricePlanText: string = "Affordable for everyone!";
export const Monthly: string = "Monthly";
export const Yearly: string = "Yearly";
export const SubscribeTitle: string = "Subscribe our newsletter get new update.";
export const SubscribeText: string = "Subscribe our newsletter to stay updated every moment";
export const Submit: string = "Submit";
export const About: string = "About";
export const AvailableOnThe: string = "Available on the";
export const AppStore: string = "AppStore";
export const GooglePlay: string = "Google Play";

//Messenger
export const Files: string = "Files";
export const ShareResources: string = "Share Resources...";
export const Note: string = "Notes";
export const ListingLog: string = "Listing Log !";
export const Todo: string = "Todo";
export const TaskList: string = "Task List...";
export const MusicTitle: string = "Music";
export const CustomizeMusic: string = "Customize Music";
export const VideoTitle: string = "Video";
export const TailorVideoContent: string = "Tailor Video Content";
export const Reminders: string = "Reminders";
export const SetReminders: string = "Set Reminders";
export const MyStatus: string = "my status";
export const StatusUpdate: string = "tap to add status Update";
export const RecentUpdate: string = "Recent Update";
export const ViewedUpdates: string = "Viewed Updates";
export const ContactTitle: string = "Contact";
export const StartWithoutDelay: string = "Start Without Delay...";
export const NotificationTitle: string = "Notification";
export const MessageArchive: string = "Message Archive...";
export const DocumentTitle: string = "Document";
export const ListingOfRecords: string = "Listing of records...";
export const FavoriteTitle: string = "Favorite";
export const FavoriteMessage: string = "Liked Message...";
export const SettingsTitle: string = "Settings";
export const SettingsMessage: string = "Setup Modification...";
export const AccountTitle: string = "Account";
export const AccountMessage: string = "Update Your Account Details";
export const SecurityTitle: string = "Security";
export const PrivacyTitle: string = "Privacy";
export const TwoStepVerificationTitle: string = "Two Step verification"; 
export const ChangeNumberTitle: string = "Change Number";
export const RequestAccount: string = "Request account info";
export const ChatTitle: string = "Chat";
export const ChatMessage: string = "Control Your Chat Backup";
export const IntegratingTitle: string = "Integrating"
export const IntegratingMessage: string = "Sync Your Other Social Account";
export const HelpTitle: string = "Help";
export const HelpMessage: string = "You are Confusion, Tell me";
export const CreateNewMessageTitle: string = "Create New Message";
export const AddContactTitle: string = "Add Contact";

//Auth
export const UserName: string = "User Name";
export const EmailAddress: string = "Email Address";
export const Password: string = "Password";
export const MobileNumber: string = "Enter Your Mobile Number";
export const Login: string = "login";
export const SignUp: string = "signup";
export const NewPassword: string = "New Password";
export const RetypePassword: string = "Retype Password";
export const RememberPassword: string = "Remember Password";

//Elements
export const HeadingsTitle: string = "H1 to H6 Headings";
export const ThisIsHeading: string = "This is heading";
export const SpanAndParagraphTitle: string = "Span and Paragraph";
export const ImagesTitle: string = "Images";
export const FontsTitle: string = "Fonts";
export const BadgeTitle: string = "Badge";
export const BackgroundColorTitle: string = "Background Color";
export const ButtonBackgroundTitle: string = "Button Background";
export const IconsTitle: string = "Icons";
export const SidebarIconTitle: string = "Sidebar Icon";
export const ImageTextTitle: string = "Image Text";
export const MainChatTitle: string = "Main Chat";
export const ChatWidthAnimationTitle: string = "Chat Width Animation";
export const TemplateButtonsTitle: string = "Template Buttons";
export const TemplateTabAndContentTitle: string = "Template Tab & Content";
export const Home: string = "Home";
export const Profile: string = "Profile";
export const MassageBoxTitle: string = "Massage Box";
export const YouUnderstand: string = "You Understand";
export const SharedDocumentTitle: string = "Shared Document";
export const SharedMediaTitle: string = "Shared Media";
export const ButtonsTitle: string = "Buttons";
export const AudioCallTitle: string = "Audio Call";
export const ReceiveAudioCallTitle: string = "Receive Audio Call";
export const ConferenceCallTitle: string = "conference call";
export const OnePersonCallTitle: string = "One Person call";
export const NoInternetTitle: string = "No Internet";
export const Reconnect: string = "Reconnect";
export const VideoCallTitle: string = "Video call";
export const VideoCallHangTitle: string = "Video call Hang";
export const VideoCallConferenceTitle: string = "video call conference";

//Blog
export const BlogDetailsTitle: string = "Mistakes You Might Be Making With Your Watch";
export const LatestBlogTitle: string = "Latest Blog";
export const LeaveYourCommentTitle: string = "Leave Your Comment";
export const Name: string = "Name";
export const Email: string = "Email";
export const Website: string = "Website";
export const Comment: string = "Comment";
export const PostComment: string = "Post Comment";
export const TrendingPosts: string = "Trending Posts";
export const SearchTitle: string = "search";
export const FollowUs: string = "follow us";
export const PopularTags: string = "Popular tags";
export const RecentPosts: string = "Recent posts";
export const CommentsTitle: string = "comments";
export const CountersTitle: string = "Counters";
