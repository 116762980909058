import ForgetPassword from "../Pages/Authentication/ForgetPassword";
import SignInClassic from "../Pages/Authentication/SignInClassic";
import SignUpClassic from "../Pages/Authentication/SignUpClassic";
import Logout from "../Components/auth/logout";
import { Route } from "./RouteList";

export const AuthRoutes = [
  { path: Route.Logout, element: <Logout /> },
  { path: Route.Login, element: <SignInClassic /> },
  { path: Route.SignUp, element: <SignUpClassic /> },
  { path: Route.ForgetPassword, element: <ForgetPassword /> },
];
