import { Fragment, useEffect, useState } from "react";
import FullPageLoader from "../Components/full-page-loader";

const Loader = () => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(false), 1000);
    return () => clearTimeout(timeout);
  }, [show]);
  return (
    <Fragment>
      {show && (
        <FullPageLoader />
      )}
    </Fragment>
  );
};

export default Loader;
