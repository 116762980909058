import { ToastContainer } from "react-toastify";
import AuthenticationObserver from "./Components/auth/observer";
import Routers from "./Routers/Routers";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const App = () => {
  const color = useSelector((state: any) => state.templateCustomizer.colorPicker);

  useEffect(() => {
    // initialize color style
    const activeColor = color.find((item: any) => item.active);
    document.documentElement.className = activeColor?.color;
  }, [color]);

  return (
    <AuthenticationObserver>
      <Routers />
      <ToastContainer />
    </AuthenticationObserver>
  );
};

export default App;
