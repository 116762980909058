import {
  deleteDoc,
  collection,
  doc,
  query,
  getDocs,
  getDoc,
  onSnapshot,
  setDoc,
  where,
  orderBy,
  limit
} from 'firebase/firestore'

import { db } from './firebase'

const getCollection = (collectionName) => collection(db, collectionName)

const getAll = async (collectionName) => {
  const snapshot = await getDocs(getCollection(collectionName))
  return snapshot.docs.map((docSnapshot) => ({
    id: docSnapshot.id,
    metadata: docSnapshot.data()
  }))
}

const getFiltered = async (collectionName, filter, order, limitNumber) => {
  const q = query(collection(db, collectionName), where(...filter[0]), orderBy(...order[0]), limit(limitNumber || 100))
  const snapshot = await getDocs(q)
  return snapshot.docs.map((docSnapshot) => ({
    id: docSnapshot.id,
    metadata: docSnapshot.data()
  }))
}

const getOne = async (collectionName, id) => {
  const snapshot = await getDoc(doc(db, collectionName, id))
  return {
    id: snapshot.id,
    exists: snapshot.exists(),
    metadata: snapshot.data()
  }
}

const getItemKey = (item) => {
  const elements = [item.id]

  if (item.updatedAt) {
    elements.push(JSON.stringify(item.updatedAt))
  }

  if (item.metadata?.updatedAt) {
    elements.push(JSON.stringify(item.metadata.updatedAt))
  }

  return elements.join('-')
}

const remove = (collectionName, id) => deleteDoc(doc(db, collectionName, id))

const subscribeOne = (collectionName, id, mutation) => onSnapshot(doc(db, collectionName, id), (docSnapshot) => {
  mutation({
    id: docSnapshot.id,
    exists: docSnapshot.exists(),
    metadata: docSnapshot.data()
  })
})

const subscribe = (collectionName, filter, mutation) => {
  const q = query(collection(db, collectionName), filter && filter[0] ? where(...filter[0]) : null)

  return onSnapshot(q, (querySnapshot) => {
    mutation(querySnapshot.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      version: docSnapshot.version,
      metadata: docSnapshot.data()
    })))
  })
}

const subscribeAll = (collectionName, mutation) => {
  const q = query(collection(db, collectionName))

  return onSnapshot(q, (querySnapshot) => {
    mutation(querySnapshot.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      version: docSnapshot.version,
      metadata: docSnapshot.data()
    })))
  })
}

const subscribeFiltered = async (collectionName, filter, order, limitNumber, mutation) => {
  const q = query(collection(db, collectionName), where(...filter[0]), orderBy(...order[0]), limit(limitNumber))
  return onSnapshot(q, (querySnapshot) => {
    mutation(querySnapshot.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      version: docSnapshot.version,
      metadata: docSnapshot.data()
    })))
  })
}

const update = (collectionName, id, data) => {
  const updateData = data
  updateData.updatedAt = new Date()
  return setDoc(doc(db, collectionName, id), updateData, { merge: true })
}

const create = (collectionName, id, data) => {
  const updateData = data
  updateData.createdAt = new Date()
  updateData.updatedAt = new Date()
  return update(collectionName, id, updateData)
}

export {
  create,
  getAll,
  getFiltered,
  getItemKey,
  getOne,
  remove,
  subscribe,
  subscribeAll,
  subscribeFiltered,
  subscribeOne,
  update
}
