import { create, getOne, subscribeOne, update } from "./db"

const collectionName = 'auth-users'

const authUserCreate = (id, data) => { 
  return create(collectionName, id, {
    ...data,
    createdAt: new Date(),
    updatedAt: new Date()
  })
}

const authUserGet = (id) => {
  return getOne(collectionName, id)
}

const authUserSubscribeById = (id, mutation) => {
  return subscribeOne(collectionName, id, mutation)
}

const authUserUpdate = (id, data) => {
  return update(collectionName, id, {
    ...data,
    updatedAt: new Date()
  })
}

export { 
  authUserCreate,
  authUserGet,
  authUserSubscribeById,
  authUserUpdate
}