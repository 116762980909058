import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
      isAuthenticated: Boolean(localStorage.getItem('Chatzy-Auth-Token')),
    },
    reducers: {
      login(state) {
        const token = "Chatzy-React";
        localStorage.setItem('Chatzy-Auth-Token', JSON.stringify(token));
        state.isAuthenticated = true;
      },
      logout(state) {
        localStorage.removeItem('Chatzy-Auth-Token');
        state.isAuthenticated = false;
      },
    },
  });
  
  export const { login, logout } = authSlice.actions;
  export default authSlice.reducer;