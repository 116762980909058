import _ from 'lodash'
import { getAll } from './db'

const collectionName = 'preset_personas'

const presetPersonasGetAll = () => getAll(collectionName)

export {
  presetPersonasGetAll
}
