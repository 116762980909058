import { getAll, subscribeAll, update } from "./db"

const getCollection = (appId) => {
  return `members/${appId}/chats`
}

const memberChatCreate = (appId, chatId, data) => {
  return update(getCollection(appId), chatId, {
    updatedAt: new Date(), 
    createdAt: new Date(),
    ...data
  })
}

const memberChatsGetAll = (appId) => {
  return getAll(getCollection(appId))
}

const memberChatUpdate = (appId, chatId, data) => {
  return update(getCollection(appId), chatId, {
    updatedAt: new Date(), 
    ...data
  })
}

const memberChatsSubscribe = (appId, callback) => {
  return subscribeAll(getCollection(appId), callback)
}

export {
  memberChatCreate,
  memberChatsGetAll,
  memberChatUpdate,
  memberChatsSubscribe
}