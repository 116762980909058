import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'user',
  initialState: {
    appId: null,
    auth: null,
    user: null,
  },
  reducers: {
    setUserAuth(state, action) {
      state.auth = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    },
    setAppId(state, action) {
      state.appId = action.payload
    },
  }
})

export const { 
  setUserAuth, 
  setUser,
  setAppId,
} = slice.actions

export default slice.reducer