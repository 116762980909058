import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Label } from "reactstrap";
import { NewPassword, RememberPassword, RetypePassword } from "../../../Constants/Constants";
import { SignUpData } from "../../../Data/Authentication/Authentication";
import { Route } from "../../../Routers/RouteList";
import { dynamicImage, Image } from "../../../Utils";

const ForgetPasswordContainer = () => {
  const navigate = useNavigate();
  return (
    <div className="login-page2 animat-rate">
      <div className="login-content-main">
        <div className="login-content">
          <div className="login-content-header">
            <Image className="for-light m-auto" src={dynamicImage("logo/landing-logo.png")} alt="sign-logo" />
            <Image className="for-dark m-auto" src={dynamicImage("logo/logo-white.png")} alt="sign-logo" />
          </div>
          <h3 className="text-center">{"Hello Everyone , We are chatzy"}</h3>
          <h6 className="text-center">{"Wellcome to chatzy please signup to your account."}</h6>
          <Form className="form2">
            <div className="form-group">
              <Label className="col-form-label">{NewPassword}</Label>
              <Input type="password" placeholder="*********" autoComplete="off" />
            </div>
            <div className="form-group">
              <Label className="col-form-label">{RetypePassword}</Label>
              <Input type="password" placeholder="*********" autoComplete="off" />
            </div>
            <div className="form-group">
              <div className="rememberchk">
                <Input id="gridCheck1" type="checkbox" />
                <Label className="ps-4" check htmlFor="gridCheck1">{RememberPassword}</Label>
              </div>
            </div>
            <ul className="medialogo">
              { /* SignUpData.map((item, index) => (
                <li key={index}>
                  <a className={`icon-btn btn-${item.color} button-effect rouded15`} href={item.url}><i className={`fa fa-${item.icon}`} /></a>
                </li>
              )) */ }
            </ul>
            <div className="form-group mb-0">
              <div className="buttons">
                <Button color="primary" className="button-effect signpbtn" onClick={() => navigate(Route.Messenger)}>{"Submit"}</Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordContainer;
