import { createSlice } from "@reduxjs/toolkit";
import { MediaSectionsData, ToDoAllData } from "../../../Data/Messenger/Messenger";

const initialState = {
  mediaOpen: Array(MediaSectionsData.length).fill(true),
  dropdownStates: Array(ToDoAllData.length).fill(false),
  dropdownData: ToDoAllData,
  appSidebarActiveTab: "",
  appSidebarWidth: 0,
};

const AppSidebarSlice = createSlice({
  name: "AppSidebarSlice",
  initialState,
  reducers: {
    closeAppSidebar: (state) => {
      document.querySelector(".chatzy-main")?.classList.remove("small-sidebar");
      document.querySelector(".app-sidebar")?.classList.remove("active");
      document.body.classList.remove("sidebar-active");
    },
    setMedia: (state, action) => {
      state.mediaOpen = state.mediaOpen.map((item, i) => (i === action.payload ? !item : item));
    },
    setDropdownStates: (state, action) => {
      state.dropdownStates = state.dropdownStates.map((item, i) => (i === action.payload ? !item : item));
    },
    setDropdownData: (state, action) => {
      const { index, data } = action.payload;
      state.dropdownData[index].dropdownData = data;
    },
    setAppSidebarActiveTab: (state, action) => {
      state.appSidebarActiveTab = action.payload;
      if (state.appSidebarWidth > 1500) document.querySelector(".chatzy-main")?.classList.remove("small-sidebar");
    },
    smallSideBarToggle: (state) => {
      document.querySelector(".chatzy-main")?.classList.add("small-sidebar");
      state.appSidebarActiveTab = "";
    },
    setAppSidebarWidth: (state, action) => {
      state.appSidebarWidth = action.payload;
    },
  },
});

export const { closeAppSidebar, setMedia, setDropdownStates, setDropdownData, setAppSidebarActiveTab, smallSideBarToggle, setAppSidebarWidth } = AppSidebarSlice.actions;
export default AppSidebarSlice.reducer;
