import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store/Hooks";
import { Route } from "./RouteList";

const RedirectRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return !isAuthenticated ? <Navigate to={Route.SignInClassic} /> : <>{children}</>;
};

export default RedirectRoute;
