import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAuth, signOut } from 'firebase/auth'
import { setAppId, setUser, setUserAuth } from "../../store/Reducers/user"
import { useNavigate } from "react-router-dom"
import Loader from "../../Layout/Loader";

export default function LogoutController() {
  const { auth } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate() 

  useEffect(() => {
    signOut(getAuth()).then(() => {
      console.log('signOut success')
      dispatch(setAppId(null))
      dispatch(setUser(null))
      dispatch(setUserAuth(null))
      navigate('/dashboard')
    }).catch(error => {
      console.log('signOut error', error)
    })
  }, [auth])
  
  return (  
    <Loader />
  )

}
