import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'personas',
  initialState: {
    personas: [],
  },
  reducers: {
    setPersonas(state, action) {
      state.personas = action.payload
    },
  }
})

export const { 
  setPersonas, 
} = slice.actions

export default slice.reducer