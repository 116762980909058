import { createSlice } from "@reduxjs/toolkit";
import Config from "../../../Config/TemplateConfig";
import { ChatContactSettingListData, ContactTabData } from "../../../Data/Messenger/Messenger";

const initialState = {
  newModal: Array(ChatContactSettingListData.length).fill(false),
  messengerActiveTab: "chat",
  subChatActiveTab: "direct",
  mobileMenu: true,
  toggleSmallSide: true,
  activeSection: null,
  videoCall: false,
  audioCall: false,
  activeContactUser: ContactTabData,
  profileToggle: false,
  sidebarToggle: true,
  profileSidebarWidth: 0,
};

const MessengerSlice = createSlice({
  name: "MessengerSlice",
  initialState,
  reducers: {
    setNewModal: (state, action) => {
      state.newModal = state.newModal.map((item, i) => (i === action.payload ? !item : item));
    },
    setMessengerActiveTab: (state, action) => {
      state.messengerActiveTab = action.payload;
    },
    setSubChatActiveTab: (state, action) => {
      state.subChatActiveTab = action.payload;
    },
    toggleVisibility: (state, action) => {
      state.activeSection = state.activeSection === action.payload ? null : action.payload;
    },
    setMobileMenu: (state) => {
      if (state.mobileMenu) {
        document.querySelector(".sidebar-toggle")?.classList.add("mobile-menu");
        console.log('enabled')
      }
      else {
        document.querySelector(".sidebar-toggle")?.classList.remove("mobile-menu");
        // scroll to top
        window.scrollTo(0, 0);
      }

      state.mobileMenu = !state.mobileMenu;
    },
    CloseAppSidebar: (state) => {
      state.toggleSmallSide = !state.toggleSmallSide;
      document.querySelector(".chatzy-main")?.classList.remove("small-sidebar");
      document.querySelector(".app-sidebar")?.classList.remove("active");
      document.body.classList.remove("sidebar-active", "main-page");
    },
    OpenAppSidebar: (state) => {
      if (state.toggleSmallSide) {
        state.toggleSmallSide = !state.toggleSmallSide;
        document.querySelector(".chatzy-main")?.classList.add("small-sidebar");
        document.querySelector(".app-sidebar")?.classList.add("active");
        document.body.classList.add("sidebar-active", "main-page");
      } else {
        state.toggleSmallSide = !state.toggleSmallSide;
        document.querySelector(".chatzy-main")?.classList.remove("small-sidebar");
        document.querySelector(".app-sidebar")?.classList.remove("active");
        document.body.classList.remove("sidebar-active", "main-page");
      }
    },
    setVideoCall: (state) => {
      state.videoCall = !state.videoCall;
    },
    setAudioCall: (state) => {
      state.audioCall = !state.audioCall;
    },
    setActiveContactUser: (state, action) => {
      state.activeContactUser = state.activeContactUser.map((item, index) => (index === action.payload ? { ...item, active: true } : { ...item, active: false }));
    },
    profileSideBarToggle: (state) => {
      if (state.profileToggle) {
        document.body.classList.remove("menu-active");
        document.querySelector(".chatzy-main")?.classList.add("small-sidebar");
        document.querySelector(".app-sidebar")?.classList.add("active");
      } else {
        document.body.classList.add("menu-active");
        document.querySelector(".chatzy-main")?.classList.remove("small-sidebar");
        document.querySelector(".app-sidebar")?.classList.remove("active");
      }
      if (state.profileSidebarWidth <= 1440) {
        if (!state.profileToggle) document.querySelector(".sidebar-toggle")?.classList.add("mobile-menu", "sidebar-overlap");
        else document.querySelector(".sidebar-toggle")?.classList.remove("mobile-menu", "sidebar-overlap");
      }
      state.profileToggle = !state.profileToggle;
    },
    OpenCloseSidebar: (state, action) => {
      const CloseSidebar = state.profileSidebarWidth <= 800 ? !action.payload : action.payload;
      if (CloseSidebar) document.querySelector(".main-nav")?.classList.add("on");
      else document.querySelector(".main-nav")?.classList.remove("on");
      state.sidebarToggle = !action.payload;
      Config.sidebar_setting = action.payload ? "no" : "";
    },
    setProfileSidebarWidth: (state, action) => {
      state.profileSidebarWidth = action.payload;
    },
  },
});

export const { setNewModal, setMessengerActiveTab, setSubChatActiveTab, toggleVisibility, setMobileMenu, CloseAppSidebar, OpenAppSidebar, setVideoCall, setAudioCall, setActiveContactUser, profileSideBarToggle, OpenCloseSidebar, setProfileSidebarWidth } = MessengerSlice.actions;
export default MessengerSlice.reducer;
