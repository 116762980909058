import { ChangeEvent, FormEvent, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { toast } from "react-toastify";
import { Button, Form, Input, Label, Nav, NavItem } from "reactstrap";
import { EmailAddress, Login, Password, SignUp, UserName } from "../../../Constants/Constants";
import { Route } from "../../../Routers/RouteList";
import { dynamicImage, Image } from "../../../Utils";
import ContinueWithGoogle from "../google";
import { useDispatch } from "react-redux";
import { setUserAuth } from "../../../store/Reducers/user";

const auth = getAuth();

const SignInClassicContainer = () => {
  const navigate = useNavigate();
  const Path = useLocation();
  const secondPart = Path.pathname
    .split("/")
    .slice(Path.pathname.split("/").length - 1)
    .map((item) => item.replace(/[!@#$%&*()_+{}[\]:;"'<>,.?/\\|`~\-=]/g, " "));
  const [credential, setCredential] = useState({ email: "", password: "" });
  const dispatch = useDispatch()
  
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setCredential({ ...credential, [event.target.name]: event.target.value });
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const { email, password } = credential;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        console.log('User authenticated')
        console.log(userCredential)
        dispatch(setUserAuth(userCredential.user))
        toast.success("Login Success...!");
        navigate(Route.Dashboard);
    })
      .catch((error) => {
      toast.error("Login error");
      console.log(error)
    });
  };

  return (
    <div className="login-page2 animat-rate">
      <div className="login-content-main">
        <div className="login-content2">
          <div className="template-tab">
            <Nav tabs>
              <NavItem>
                <Link className={`nav-link ${secondPart[0] === "sign in classic" ? "active" : ""}`} to={Route.Login}>
                  {Login}
                </Link>
              </NavItem>
              <NavItem>
                <Link className={`nav-link ${secondPart[0] === "sign up classic" ? "active" : ""}`} to={Route.SignUp}>
                  {SignUp}
                </Link>
              </NavItem>
            </Nav>
          </div>
        </div>
        <div className="login-content">
          <div className="login-content-header">
            <Link to={Route.Dashboard}>
              <Image className="img-fluid for-light m-auto" src={dynamicImage("logo/logo.svg")} alt="images" />
              <Image className="img-fluid for-dark m-auto" src={dynamicImage("logo/logo.svg")} alt="images" />
            </Link>
          </div>
          <h3 className="text-center">{"Hello Everyone!"}</h3>
          <h6 className="text-center">{"Login to your account."}</h6>
          <Form className="form2" onSubmit={handleSubmit}>
            {secondPart[0] === "sign up classic" && (
              <div className="form-group">
                <Label className="col-form-label">{UserName}</Label>
                <Input type="text" value={"Test User"} />
              </div>
            )}
            <div className="form-group">
              <Label className="col-form-label">{EmailAddress}</Label>
              <Input type="email" name="email" value={credential.email} onChange={(e) => handleChange(e)} />
            </div>
            <div className="form-group">
              <Label className="col-form-label">{Password}</Label>
              <Input type="password" autoComplete="off" name="password" value={credential.password} onChange={(e) => handleChange(e)} />
            </div>
            {secondPart[0] === "sign in classic" && (
              <div className="form-group">
                <div className="rememberchk">
                  <Input id="gridCheck1" type="checkbox" />
                  <Label className="ps-4" htmlFor="gridCheck1" check>
                    {"Remember Me."}
                  </Label>
                  <h6 className="pull-right">{"Forgot Password?"}</h6>
                </div>
              </div>
            )}
            <ul className="medialogo">
              <li>
                <ContinueWithGoogle success={() => navigate('/dashboard') } />
              </li>
            </ul>
            <div className="form-group mb-0">
              <div className="buttons">
                <Button color="primary" className="button-effect">
                  {secondPart[0] === "sign in classic" ? Login : SignUp}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SignInClassicContainer;
