import { functions, httpsCallable } from './firebase'

const anonymousChatsSave = async (anonymousUid) => {
  const call = httpsCallable(functions, 'chatAnonymousChatsSave')
  const { data } = await call({ anonymousUid })

  return data
}

export { 
  anonymousChatsSave
}