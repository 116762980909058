import React from 'react'
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { auth } from '../../api/firebase'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { setUserAuth } from '../../store/Reducers/user'
import { Button } from 'reactstrap'
import { dynamicImage, Image } from '../../Utils'
//import actions from '../../core/actions'
//import { getMixpanel } from '../../api/mixpanel'

//const mixpanel = getMixpanel()
const provider = new GoogleAuthProvider()
provider.setCustomParameters({
})

const ContinueWithGoogle = ({ success }) => {
  const dispatch = useDispatch()

  const signIn = async () => {
    const response = await signInWithPopup(auth, provider)

    if (response.user) {
      console.log('user', response.user)
      //mixpanel.track('User account sign in or signup A15', { method: 'google' })

      dispatch(setUserAuth(response.user))

      success()
    }
  }

  return (
    <Button onClick={signIn} color="white" className="google">
      <Image src={dynamicImage('logo/google.svg')} alt="Google" />
      <span>Continue with Google</span>
    </Button>
  )
}

export default ContinueWithGoogle
