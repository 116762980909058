import { Route } from "../../Routers/RouteList";

export const HeaderData = [
  {
    dropdownTitle: "Blog",
    type: "link",
    active: false,
    dropdown: [
      {
        title: "Blog Right sidebar",
        url: Route.RightSidebar,
      },

      {
        title: "Blog Left sidebar",
        url: Route.LeftSidebar,
      },

      {
        title: "Blog Details",
        url: Route.SidebarDetails,
      },

      {
        title: "Blog No sidebar",
        url: Route.NoSidebar,
      },
    ],
  },
  {
    dropdownTitle: "Authentication",
    type: "link",
    active: false,
    dropdown: [
      {
        title: "Signin Classic",
        url: Route.SignInClassic,
      },
      {
        title: "Signup Classic",
        url: Route.SignUpClassic,
      },
      {
        title: "Forget Password",
        url: Route.ForgetPassword,
      },
      {
        title: "Logout",
        url: Route.Logout,
      },
    ],
  },
  {
    dropdownTitle: "Bonus page",
    type: "link",
    active: false,
    dropdown: [
      {
        title: "About",
        url: Route.About,
      },
      {
        title: "Landing",
        url: Route.Landing,
      },
      {
        title: "FAQ",
        url: Route.Faq,
      },
      {
        title: "Elements",
        url: Route.Elements,
      },
      {
        title: "Signin",
        url: Route.Login,
      },
      {
        title: "Signup",
        url: Route.SignUp,
      },
      {
        title: "Full Messenger",
        url: Route.Messenger,
      },
    ],
  },
];
