import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Loader from "../Layout/Loader";

export default function NavigateTo({ to }) {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(to)
  }, [])

  return (
    <Loader />
  )
}