import { createSlice } from "@reduxjs/toolkit";
import { HeaderData } from "../../Data/Layout/Header";

const initialState = {
  DropdownOpen: Array(HeaderData.length).fill(false),
  isNavbar: false,
};

const LayoutSlice = createSlice({
  name: "LayoutSlice",
  initialState,
  reducers: {
    setDropdownOpen: (state, action) => {
      state.DropdownOpen = state.DropdownOpen.map((item, i) => (i === action.payload ? !item : item));
    },
    setNavbar: (state) => {
      state.isNavbar = !state.isNavbar;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    },
  },
});

export const { setDropdownOpen ,setNavbar} = LayoutSlice.actions;
export default LayoutSlice.reducer;
