import { AlertCircle, ArrowDownLeft, ArrowDownRight, ArrowUpRight, CornerLeftDown, Crosshair, ExternalLink, MapPin, MessageSquare, Phone, PhoneIncoming, PhoneMissed, PhoneOutgoing, Share2, Smartphone, Trash2, Users, Video } from "react-feather";
import { Href } from "../../Constants/Constants";
import { Pagination } from "swiper/modules";

//App Sidebar
export const AppListData = [
  { type: "file", icon: "gallery" },
  { type: "notes", icon: "share" },
  { type: "todo", icon: "document" },
  { type: "music", icon: "music" },
  { type: "video", icon: "video" },
  { type: "reminder", icon: "reminder" },
];

export const FileTabListData = ["Media", "Link", "Docs"];

export const MediaSectionsData = [
  {
    date: "12/12/2024",
    badgeCount: 5,
    images: [
      { mediaClass: "big", src: "1" },
      {
        mediaClass: "small",
        src: "2",
        Children: [{ mediaClass: "small", src: "3" }],
      },
      {
        mediaClass: "small",
        src: "4",
        Children: [{ mediaClass: "small fashion", src: "5" }],
      },
    ],
  },
  {
    date: "10/01/2024",
    badgeCount: 2,
    images: [
      { mediaClass: "big", src: "1_1" },
      { mediaClass: "small", src: "2" },
    ],
  },
  {
    date: "30/04/2024",
    badgeCount: 4,
    images: [
      { mediaClass: "big", src: "1_2" },
      {
        mediaClass: "small",
        src: "2",
        Children: [{ mediaClass: "small", src: "3" }],
      },
      { mediaClass: "small", src: "4" },
    ],
  },
  {
    date: "10/01/2024",
    badgeCount: 8,
    images: [
      { mediaClass: "big", src: "1_3" },
      {
        mediaClass: "small",
        src: "2",
        Children: [{ mediaClass: "small", src: "3" }],
      },
      {
        mediaClass: "small",
        src: "4",
        Children: [{ mediaClass: "small fashion", src: "5" }],
      },
      { mediaClass: "small", src: "2" },
      { mediaClass: "small", src: "3" },
      { mediaClass: "small", src: "4" },
    ],
  },
];

export const LinksData = [
  {
    title: "Jquery Template",
    time: "12:05 PM Today",
    href: "https://themeforest.net/item/endless-react-admin-template/25365098",
    imgSrc: "file_icons/12.png",
    subtitle: "React Template",
    description: "Functionality integration project.",
  },
  {
    title: "Multikart Template",
    time: "05:12 AM Today",
    href: "https://themeforest.net/item/multikart-responsive-vuejs-ecommerce-template/25174665",
    imgSrc: "file_icons/3.png",
    subtitle: "Multipurpose Vuejs.",
    description: "Template is a multi-use Vue template.",
  },
  {
    title: "Unice-Multipurpose",
    time: "03:26 PM",
    href: "https://themeforest.net/item/unice-angular-multipurpose-template/24776272",
    imgSrc: "file_icons/8.png",
    subtitle: "Angular Template.",
    description: "Unice is a Perfect Respon.",
  },
  {
    title: "Endless-Angular",
    time: "02:26 AM",
    href: "https://themeforest.net/item/endless-angular-admin-template/23884779",
    imgSrc: "file_icons/12.png",
    subtitle: "Endless Document.",
    description: "Help you understand angular.",
  },
  {
    title: "Bigdeal-eCommerce",
    time: "04:00 PM",
    href: "https://themeforest.net/item/bigdeal-ecommerce-htms-template/24809149",
    imgSrc: "file_icons/9.png",
    subtitle: "HTML Template.",
    description: "eCommerce HTML Template.",
  },
  {
    title: "Multikart-Responsive.",
    time: "11:05 PM",
    href: "https://themeforest.net/item/multikart-responsive-react-ecommerce-template/23067773",
    imgSrc: "file_icons/3.png",
    subtitle: "Multipurp eComme.",
    description: "Well with multi-purpose websites.",
  },
  {
    title: "Creative - Responsive",
    time: "12:26 PM",
    href: "https://themeforest.net/item/creative-responsive-admin-template/24978419",
    imgSrc: "file_icons/11.png",
    subtitle: "Dashboard Templa.",
    description: "Creative Admin is a full featured.",
  },
  {
    title: "eComme Template",
    time: "12:26 PM",
    href: "https://themeforest.net/item/multikart-responsive-angular-ecommerce-template/22905358",
    imgSrc: "file_icons/3.png",
    subtitle: "Responsive Ang.",
    description: "Multikart – Multipurpose.",
  },
  {
    title: "Multikart Templat.",
    time: "12:26 PM",
    href: "https://themeforest.net/item/multikart-responsive-ecommerce-htms-template/22809967",
    imgSrc: "file_icons/10.png",
    subtitle: "Multi Responsive.",
    description: "Ecommerce HTML Template.",
  },
  {
    title: "BigBoost Template",
    time: "04:26 PM",
    href: "https://themeforest.net/item/bigboost-ecommerce-htms-template/24168053",
    imgSrc: "file_icons/7.png",
    subtitle: "Fully Responsive.",
    description: "Multiple Header Varations.",
  },
  {
    title: "App Landing",
    time: "10:05 PM 20/05/2024",
    href: "https://themeforest.net/item/unice-app-landing-corporate-and-portfolio-multipurpose-template/24581311",
    imgSrc: "file_icons/4.png",
    subtitle: "Multi-Purpos Template.",
    description: "Unice is a Perfect Responsive.",
  },
  {
    title: "Reno - Tools Store",
    time: "12:26 PM",
    href: "https://themeforest.net/item/reno-multipurpose-htms-template/24141678",
    imgSrc: "file_icons/6.png",
    subtitle: "Reno Template is a busines.",
    description: "Android Mobile or tablets.",
  },
  {
    title: "Shop - Mart",
    time: "12:26 PM 03/11/2024",
    href: "https://themeforest.net/item/shopmart-multipurpose-shopify-theme/24040917?s_rank=12",
    imgSrc: "file_icons/5.png",
    subtitle: "Shop Mart Landing Page.",
    description: "This is App Landing Template.",
  },
];

export const DocumentsData = [
  {
    title: "messenger.html",
    date: "2, October 2024",
    iconClass: "fa-file-code-o",
    bgColorClass: "bg-light-danger",
  },
  {
    title: "chapter1.MP4",
    date: "3, October 2024",
    iconClass: "fa-file-video-o",
    bgColorClass: "bg-light-success",
  },
  {
    title: "salary.xlsx",
    date: "5, October 2024",
    iconClass: "fa-file-word-o",
    bgColorClass: "bg-light-primary",
  },
  {
    title: "document.pdf",
    date: "7, October 2024",
    iconClass: "fa-file-pdf-o",
    bgColorClass: "bg-light-warning",
  },
  {
    title: "details.txt",
    date: "20, October 2024",
    iconClass: "fa-file-text-o",
    bgColorClass: "bg-light-danger",
  },
  {
    title: "messenger.html",
    date: "2, October 2024",
    iconClass: "fa-file-code-o",
    bgColorClass: "bg-light-success",
  },
];

export const ToDoAllData = [
  { title: "Landing Redesign", dropdownData: "Assign To" },
  { title: "Project Report", dropdownData: "Assign To" },
  { title: "Grocery Store App", dropdownData: "Assign To" },
  { title: "Background Image", dropdownData: "Assign To" },
];

export const ToDoDropdownData = ["Josephin john", "Lynetin john", "Sufiya john", "Jhon john"];

export const VideoData = ["https://giphy.com/embed/xl2zRzM8sVo3td58kS", "https://giphy.com/embed/vNp62AnPg1IiPFnR3o"];

export const ReminderModalData = [
  { number: "15", text: "minutes" },
  { number: "1", text: "hour" },
  { number: "5 pm", text: "today" },
];

export const SongsListData = [
  {
    title: "New Rules",
    artist: "Dua Lipa",
    link: "https://www.bensound.com/bensound-music/bensound-memories.mp3",
    thumb: "https://a10.gaanacdn.com/images/albums/47/1908547/crop_175x175_1908547.jpg",
  },
  {
    title: "Señorita",
    artist: "Shawn Mendes, Camila Cabello",
    link: "https://www.bensound.com/bensound-music/bensound-creativeminds.mp3",
    thumb: "https://a10.gaanacdn.com/images/albums/72/2657072/crop_175x175_2657072.jpg",
  },
  {
    title: "Wake Me Up",
    artist: "Avicii",
    link: "https://www.bensound.com/bensound-music/bensound-acousticbreeze.mp3",
    thumb: "https://i1.sndcdn.com/artworks-000143888529-4mwefu-t500x500.jpg",
  },
];

//Main Sidebar
export const MainSidebarListData = [
  { class: "step1", type: "status", icon: "messege" },
  { type: "contact-list", icon: "userbox" },
  { type: "notification", icon: "notification" },
  { type: "document", icon: "document" },
  { type: "favorite", icon: "favourite" },
  { class: "step2", type: "settings", icon: "setting" },
];

export const ContactStatusData = [
  { id: "1", image: "1", title: "Josephin water", time: "today , 8:30am", status: false ,active:false},
  { id: "2", image: "2", title: "Jony Lynetin", time: "today , 10:30am", status: false ,active:false},
  { id: "3", image: "3", title: "Sufiya Elija", time: "today , 11:00am", status: false ,active:false},
  { id: "4", image: "4", title: "Mukrani Pabelo", time: "today , 9:55am", status: false ,active:false},
  { id: "5", image: "5", title: "Pabelo Mukrani", time: "today , 12:05am", status: false ,active:false},
  { id: "6", image: "6", title: "kelin jasen", time: "today , 8:30am", status: true ,active:false},
  { id: "7", image: "7", title: "Sufiya Eliza", time: "30 Mins Ago", status: true ,active:false},
];

export const ContactData = [
  { avatar: "avtar/1.jpg", title: "Josephin water", text: "Hi, i am josephin. How are you.. ! There are many variations of passages.", date: "22/10/23", svg: "right-2", class: "online", active: "active" },
  { avatar: "contact/2.jpg", title: "Jony Lynetin", text: "Hello 🙋‍♂️", date: "30/11/23", badge: true, class: "online" },
  { avatar: "contact/3.jpg", title: "Sufiya Elija", text: "I need job, please help me.", date: "15/06/23", class: "unreachable", status: "Sending", color: "dark" },
  { avatar: "contact/4.jpg", title: "Pabelo Mukrani", text: "Hi, i am josephin. How are you.. ! There are many variations of passages.", date: "Just Now", class: "busy", status: "Failed", color: "danger" },
  { avatar: "contact/1.jpg", title: "Josephin water", text: "Hi, i am josephin. How are you.. ! There are many variations of passages.", date: "22/10/23", class: "offline" },
  { avatar: "contact/2.jpg", title: "Jony Lynetin", text: "Hello ..🙂", date: "30/11/23", badge: true, class: "online", svg: "right-2" },
  { avatar: "contact/3.jpg", title: "Sufiya Elija", text: "I need job, please help me.", date: "15/06/23", class: "unreachable", status: "Sending", color: "dark" },
  { avatar: "contact/2.jpg", title: "Jony Lynetin", text: "Hello 💌", date: "30/11/23", class: "online" },
  { avatar: "contact/1.jpg", title: "Josephin water", text: "Hi, i am josephin. How are you.. ! There are many variations of passages.", date: "22/10/23", class: "offline", image: true },
  { avatar: "contact/2.jpg", title: "Jony Lynetin", text: "Hello...", date: "30/11/23", class: "online", svgIcon: true },
  { avatar: "contact/3.jpg", title: "Sufiya Elija", text: "I need job, please help me.", date: "15/06/23", class: "unreachable", status: "Sending", color: "dark" },
  { avatar: "contact/4.jpg", title: "Pabelo Mukrani", text: "Sent you image", date: "Just Now", class: "busy", status: "Failed", color: "danger", textSvg: true },
  { avatar: "contact/1.jpg", title: "Josephin water", text: "Hi, i am josephin. How are you.. ! There are many variations of passages.", date: "22/10/23", class: "offline", status: "Seen", color: "success" },
];

export const NotificationData = [
  { image: "1", title: "Josephin water", text: "Upload New Photos", class: "offline" },
  { word: "A", title: "Jony Today Birthday", text: "Upload New Photos", class: "bg-success offline" },
  { image: "2", title: "Sufiya Elija", text: "Comment On your Photo", class: "offline" },
  { image: "3", title: "Pabelo Mukrani", text: "Invite Your New Friend", class: "unreachable" },
  { word: "AC", title: "Pabelo Mukrani", text: "Update Profile Picture", class: "bg-success offline" },
];

export const FavoriteData = [
  { image: "1", title: "Josephin water", text: "Alabma , USA", class: "offline" },
  { image: "2", title: "Jony Lynetin", text: "Los Angeles, California", class: "online" },
  { image: "3", title: "Sufiya Elija", text: "Glasgow , Scotland", class: "busy" },
  { image: "4", title: "Pabelo Mukrani", text: "Leicester , England", class: "unreachable" },
  { image: "5", title: "Kristin Watson", text: "Alabma , Scotland", class: "offline" },
  { image: "3", title: "Jesus Watson", text: "Alabma", class: "online" },
];

export const PrivacyData = [
  { title: "Last seen", text: "turn on this setting to whether your contact can see last seen or not.", checked: true },
  { title: "Profile Photo", text: "turn on this setting to whether your contact can see your profile or not." },
  { title: "About", text: "turn on this setting to whether your contact can see about status or not." },
  { title: "Status", text: "turn on this setting to whether your contact can see your status or not." },
  { title: "Read receipts", text: "If turn off this option you won't be able to see read recipts from contact. read receipts are always sent for group chats." },
  { title: "Groups", text: "turn on this setting to whether your contact can add in groups or not." },
  { title: "Screen Lock(Require Touch ID)", checked: true },
];

export const ChatBackupData = [{ title: "Auto Backup", checked: true }, { title: "Include document" }, { title: "Include Videos" }];

export const ChatData = [{ title: "Archive all chat" }, { title: "Clear all chats" }, { title: "Delete all chats", class: "font-danger" }];

export const IntegratingData = [
  { class: "fb", url: "https://www.facebook.com/login", icon: "facebook", title: "Facebook" },
  { class: "insta", url: "https://www.instagram.com/accounts/login/?hl=en", icon: "instagram", title: "instagram" },
  { class: "twi", url: "https://twitter.com/login", icon: "twitter", title: "twitter" },
  { class: "ggl", url: "https://accounts.google.com/signin/v2/identifier?service=mail&passive=true&rm=false&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&ss=1&scc=1&ltmpl=default&ltmplcache=2&emr=1&osid=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin", icon: "google", title: "google" },
  { class: "slc", url: Href, icon: "slack", title: "Slack", link: true },
];

export const HelpData = ["FAQ", "Contact Us", "Terms and Privacy Policy", "Licenses", "2024 - 20 Powered by Pixelstrap"];

export const RecentData = [
  { image: "1", title: "My status" },
  { image: "2", title: "Jesus wa..." },
  { image: "3", title: "Mari" },
  { image: "4", title: "Kristin wa..." },
  { image: "5", title: "Lea" },
  { image: "2", title: "Jesus wa..." },
];

export const ChatContactSettingListData = [
  { color: "primary", title: "new chat", icon: <MessageSquare /> },
  { color: "success", title: "new call", icon: <Phone /> },
  { color: "danger", title: "new contact", icon: <Users /> },
];

export const MessengerTabsData = [
  { class: "step4", title: "Chat", icon: "messege-icon", type: "chat" },
  { title: "Call", icon: "phone-icon", type: "call" },
  { title: "Contact", icon: "user-icon", type: "contact" },
];

export const ChatTabsData = [
  { title: "Direct", type: "direct" },
  { title: "Group", type: "group" },
];

export const ChatFriendContent = [
  { class: "stroke-dark", icon: "clear", title: "Clear dark" },
  { class: "fill-dark", icon: "block", title: "Block" },
];

export const ContactPollData = [
  {
    mainClass: "d-flex align-items-center gap-4",
    contactPoll: [
      { class: "location-more-features", icon: "location", title: "Location" },
      { class: "camera-more-features", icon: "camera", title: "Camera" },
      { class: "audio-more-features", icon: "audio", title: "Audio" },
    ],
  },
  {
    mainClass: "d-flex align-items-center gap-4",
    contactPoll: [
      { class: "gallery-more-features", icon: "gallery-fill", title: "Gallery" },
      { class: "video-more-features", icon: "video", title: "Video" },
      { class: "contact-more-features", icon: "contact", title: "contact" },
    ],
  },
];

export const CallTabData = [
  { title: "All", type: "all" },
  { icon: <PhoneIncoming />, type: "incoming" },
  { icon: <PhoneOutgoing />, type: "outgoing" },
  { icon: <PhoneMissed />, type: "cancel" },
];

export const CallData = [
  { id: 1, onlineStatus: "offline", avatar: "2", name: "Jony Lynetin", icon: <ArrowDownLeft />, time: "3:30 pm", color: "success", callStatusIcon: <Phone /> },
  { id: 2, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <ArrowDownRight />, time: "3:10 pm", color: "success", callStatusIcon: <Video /> },
  { id: 3, onlineStatus: "offline", avatar: "2", name: "Jony Lynetin", icon: <CornerLeftDown className="missed" />, time: "3:00 pm", color: "danger", callStatusIcon: <Phone /> },
  { id: 4, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <ArrowDownRight />, time: "3:00 pm", color: "success", callStatusIcon: <Video /> },
  { id: 5, onlineStatus: "offline", avatar: "2", name: "Jony Lynetin", icon: <ArrowDownRight />, time: "3:00 pm", color: "success", callStatusIcon: <Phone /> },
  { id: 6, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <ArrowDownRight />, time: "3:00 pm", color: "success", callStatusIcon: <Video /> },
  { id: 7, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <ArrowDownRight />, time: "3:10 pm", color: "success", callStatusIcon: <Video /> },
  { id: 8, onlineStatus: "offline", avatar: "2", name: "Jony Lynetin", icon: <ArrowDownRight />, time: "3:00 pm", color: "success", callStatusIcon: <Phone /> },
  { id: 9, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <ArrowDownRight />, time: "3:00 pm", color: "success", callStatusIcon: <Video /> },
  { id: 10, onlineStatus: "offline", avatar: "2", name: "Jony Lynetin", icon: <ArrowDownRight />, time: "3:00 pm", color: "success", callStatusIcon: <Phone /> },
  { id: 11, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <ArrowDownRight />, time: "3:00 pm", color: "success", callStatusIcon: <Video /> },
  { id: 12, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <ArrowUpRight />, time: "3:10 pm", color: "success", callStatusIcon: <Video /> },
  { id: 13, onlineStatus: "offline", avatar: "2", name: "Jony Lynetin", icon: <ArrowUpRight />, time: "3:00 pm", color: "success", callStatusIcon: <Phone /> },
  { id: 14, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <ArrowUpRight />, time: "3:00 pm", color: "success", callStatusIcon: <Video /> },
  { id: 15, onlineStatus: "offline", avatar: "2", name: "Jony Lynetin", icon: <ArrowUpRight />, time: "3:00 pm", color: "success", callStatusIcon: <Phone /> },
  { id: 16, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <ArrowUpRight />, time: "3:00 pm", color: "success", callStatusIcon: <Video /> },
  { id: 17, onlineStatus: "offline", avatar: "2", name: "Jony Lynetin", icon: <CornerLeftDown className="missed" />, time: "3:00 pm", color: "danger", callStatusIcon: <Phone /> },
  { id: 18, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <CornerLeftDown className="missed" />, time: "3:10 pm", color: "danger", callStatusIcon: <Video /> },
  { id: 19, onlineStatus: "offline", avatar: "2", name: "Jony Lynetin", icon: <CornerLeftDown className="missed" />, time: "3:00 pm", color: "danger", callStatusIcon: <Phone /> },
  { id: 20, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <CornerLeftDown className="missed" />, time: "3:00 pm", color: "danger", callStatusIcon: <Video /> },
  { id: 21, onlineStatus: "offline", avatar: "2", name: "Jony Lynetin", icon: <CornerLeftDown className="missed" />, time: "3:00 pm", color: "danger", callStatusIcon: <Phone /> },
  { id: 22, onlineStatus: "online", avatar: "1", name: "Jony Lynetin", icon: <CornerLeftDown className="missed" />, time: "3:00 pm", color: "danger", callStatusIcon: <Video /> },
];

export const CallNotificationData = [
  { title: "Incoming call", time: "15 Minutes ago 5:10 (22/10/24)" },
  { title: "outgoing call", time: "30 Minutes ago 10:30 (12/09/24)" },
  { title: "Missed call", time: "1 Minutes ago 8:30 (28/08/24)" },
  { title: "Missed call", time: "10 Minutes ago 9:10 (18/01/24)" },
  { title: "Missed call", time: "35 Minutes ago 9:10 (17/01/24)" },
  { title: "Missed call", time: "80 Minutes ago 12:05 17/01/24)" },
  { title: "Incoming call", time: "15 Minutes ago 15:20 (28/02/24)" },
  { title: "Incoming call", time: "10 Minutes ago 20m 26s (10/3/24)" },
];

export const ContactTabData = [
  { active: false, avatar: "2", userName: "Lea", number: "+21 3523 25544", onlineStatus: "offline", gender: "male", birthday: "1 april 1995" },
  { active: true, avatar: "1", userName: "Josephin water", number: "+54 541447 255", onlineStatus: "online", gender: "male", birthday: "9 april 1994" },
  { active: false, avatar: "3", userName: "Mari", number: "+58 2564 02554", onlineStatus: "busy", gender: "female", birthday: "7 july 1990" },
  { active: false, avatar: "4", userName: "Kristin Watson", number: "+44 55124 2524", onlineStatus: "unreachable", gender: "male", birthday: "10 september 1994" },
  { active: false, avatar: "5", userName: "Jony Lynetin", number: "+54 541447 255", onlineStatus: "online", gender: "female", birthday: "10 october 2000" },
];

export const SocialInformationData = [
  { class: "fb", url: "https://www.facebook.com/login", icon: "facebook", title: "Facebook" },
  { class: "twi", url: "https://twitter.com/login", icon: "twitter", title: "twitter" },
  { class: "ggl", url: "https://accounts.google.com/signin/v2/identifier?service=mail&passive=true&rm=false&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&ss=1&scc=1&ltmpl=default&ltmplcache=2&emr=1&osid=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin", icon: "google", title: "google" },
];

export const ContactIconData = [
  { svgClass: "stroke-primary", svgIcon: "search-toggl", title: "Wallpaper" },
  { svgClass: "fill-primary", svgIcon: "user-group", title: "Group" },
  { svgClass: "stroke-primary", svgIcon: "stroke-share", title: "Share" },
];

export const MessagesProfileData = [
  { image: "contact/2.jpg", title: "Alan josheph", time: "01:35 AM", text: "Hi I am Alan," },
  { image: "contact/3.jpg", title: "Josephin water", time: "01:35 AM", text: "Can you help me to find best chat app?." },
];

export const GroupChatProfileData = [
  { image: "avtar/teq.jpg", title: "Tech Ninjas", text: "johan, deo, Sufiya Elija, Pabelo & you" },
  { image: "avtar/family.jpg", title: "Family Ties", text: "Mukrani, deo & you" },
];

export const ContactInformationData = [
  { url: Href, icon: <Smartphone />, text: "+12 3456789587" },
  { url: "https://themeforest.net/user/pixelstrap/portfolio", icon: <Crosshair />, text: "https://pixelstrap" },
  { url: Href, icon: <MapPin />, text: "1766 Fidler Drive Texas, 78238." },
];

export const ContactFormData = [
  { title: "Block", check: false },
  { title: "Mute", check: false },
  { title: "Get Notification", check: true },
];

export const OtherContactData = [
  { title: "share Contact", icon: <Share2 /> },
  { title: "Clear Chat", icon: <Trash2 /> },
  { title: "Export Chat", icon: <ExternalLink /> },
  { title: "Report Contact", icon: <AlertCircle /> },
];

export const ColorPickerData = [
  { class: "color1", color: "style1", active: true },
  { class: "color2", color: "style2", active: false },
  { class: "color3", color: "style3", active: false },
  { class: "color4", color: "style4", active: false },
  { class: "color5", color: "style5", active: false },
];

export const TemplateLayoutData = [
  { active: true, layoutValue: "" },
  { active: false, layoutValue: "dark-sidebar" },
  { active: false, layoutValue: "dark" },
  { active: false, layoutValue: "colorfull" },
];

export const ChatWallpaperData = [
  { id: 1, active: false, class: "bg-color", wallpaperClass: "bg-default", wallpaperCss: "-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(1, 170, 133, 0.1)))" },
  { id: 2, active: false, class: "bg-size", wallpaperImage: "2" },
  { id: 3, active: false, class: "bg-size", wallpaperImage: "3" },
  { id: 4, active: false, class: "bg-size", wallpaperImage: "4" },
  { id: 5, active: false, class: "bg-size", wallpaperImage: "5" },
  { id: 6, active: true, class: "bg-size", wallpaperImage: "1" },
  /*
  { id: 7, active: false, class: "bg-color", wallpaperClass: "grediant-1", wallpaperCss: "linear-gradient(359.3deg, rgba(1, 170, 133, 0.1) 1%, rgba(187, 187, 187, 0) 70.9%)" },
  { id: 8, active: false, class: "bg-color", wallpaperClass: "grediant-2", wallpaperCss: "radial-gradient(328px at 2.9% 15%, rgb(191, 224, 251) 0%, rgb(232, 233, 251) 25.8%, rgb(252, 239, 250) 50.8%, rgb(234, 251, 251) 77.6%, rgb(240, 251, 244) 100.7%)" },
  { id: 9, active: false, class: "bg-color", wallpaperClass: "grediant-3", wallpaperCss: "linear-gradient(109.6deg, rgb(223, 234, 247) 11.2%, rgb(244, 248, 252) 91.1%)" },
  { id: 10, active: false, class: "bg-color", wallpaperClass: "grediant-4", wallpaperCss: "linear-gradient(-109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)" },
  { id: 11, active: false, class: "bg-color", wallpaperClass: "grediant-5", wallpaperCss: "radial-gradient(circle at 10% 20%, rgb(239, 246, 249) 0%, rgb(206, 239, 253) 90%)" },
  { id: 12, active: false, class: "bg-color", wallpaperClass: "grediant-6", wallpaperCss: "radial-gradient(circle at 10% 20%, rgb(226, 240, 254) 0%, rgb(255, 247, 228) 90%)" },
  */
];

export const TourSteps = [
  {
    selector: ".step1",
    content: "Check Status here",
  },
  {
    selector: ".step2",
    content: "You can change settings by clicking here",
  },
  {
    selector: ".step3",
    content: "Change mode",
  },
  {
    selector: ".step4",
    content: "Start chat",
  },
];

export const PhotosChatData = [
  { class: 1, image: "6", avatar: "7", avatarClass: 2 },
  { class: 3, image: "8", avatar: "9", avatarClass: 4 },
];

export const PricePlanSettingsData = {
  slidesPerView: 3,
  spaceBetween: 30,
  loop: true,
  centeredSlides: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    601: {
      slidesPerView: 2,
    },
    1070: {
      slidesPerView: 3,
      spaceBetween: 25,
    },
    1600: {
      slidesPerView: 3,
    },
  },
};

export const TeamExpertSettingsData = {
  slidesPerView: 4,
  spaceBetween: 30,
  loop: true,
  autoplay: { delay: 2500, disableOnInteraction: false },
  modules: [Pagination],
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    575: {
      slidesPerView: 2,
    },
    1070: {
      slidesPerView: 3,
      spaceBetween: 25,
    },
    1600: {
      slidesPerView: 4,
    },
  },
};
