import { createSlice } from "@reduxjs/toolkit";
import Config from "../../Config/TemplateConfig";
import { ChatWallpaperData, ColorPickerData, TemplateLayoutData } from "../../Data/Messenger/Messenger";
import { dynamicImage } from "../../Utils";

const initialState = {
  layoutType: true,
  colorPicker: ColorPickerData,
  templateLayout: TemplateLayoutData,
  chatWallpaper: ChatWallpaperData,
};

const TemplateCustomizerSlice = createSlice({
  name: "TemplateCustomizer",
  initialState,
  reducers: {
    setLayoutType: (state) => {
      if (state.layoutType) {
        Config.layout_type = "rtl";
        document.body.classList.add("rtl");
        document.querySelector(".rtl-setting")?.classList.add("rtl");
      } else {
        Config.layout_type = "";
        document.body.classList.remove("rtl");
        document.querySelector(".rtl-setting")?.classList.remove("rtl");
      }
      state.layoutType = !state.layoutType;
    },
    setColorPicker: (state, action) => {
      Config.color = action.payload;
      //document.documentElement.className = action.payload;
      state.colorPicker = state.colorPicker.map((item) => (item.color === action.payload ? { ...item, active: true } : { ...item, active: false }));
    },
    setTemplateLayoutData: (state, action) => {
      Config.sidebar_layout = action.payload;
      if (action.payload === "") {
        document.body.classList.remove("dark-sidebar", "dark", "colorfull");
      } else if (action.payload === "dark-sidebar") {
        document.body.classList.add("dark-sidebar");
        document.body.classList.remove("dark", "colorfull");
      } else if (action.payload === "dark") {
        document.body.classList.add("dark");
        document.body.classList.remove("dark-sidebar", "colorfull");
      } else {
        document.body.classList.add("colorfull");
        document.body.classList.remove("dark-sidebar", "dark");
      }
      state.templateLayout = state.templateLayout.map((item) => (item.layoutValue === action.payload ? { ...item, active: true } : { ...item, active: false }));
    },
    setChatWallpaper: (state, action) => {
      const updateBackground = (selector: string, wallpaper: string, wallpaperCss: string) => {
        const element = document.querySelector(selector) as HTMLElement;
        if (element) {
          element.style.backgroundImage = wallpaper ? `url(${dynamicImage(`wallpaper/${wallpaper}.jpg`)})` : wallpaperCss;
          element.style.backgroundBlendMode = "unset";
        }
      };
      updateBackground(".chat-content .messages", action.payload.wallpaperImage, action.payload.wallpaperCss);
      updateBackground("#group_chat", action.payload.wallpaperImage, action.payload.wallpaperCss);
      Config.wallpaper = action.payload.wallpaperImage || action.payload.wallpaperClass;
      state.chatWallpaper = state.chatWallpaper.map((item) => ({ ...item, active: item.id === action.payload.id }));
    },
  },
});

export const { setLayoutType, setColorPicker, setTemplateLayoutData, setChatWallpaper } = TemplateCustomizerSlice.actions;

export default TemplateCustomizerSlice.reducer;
